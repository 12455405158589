import { PageProps } from 'gatsby';
import { ExtractData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import extract from '~/params/extract.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const EspaceExtractUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { extractId },
  } = props;

  const model = new ExtractData({ espaceId: espace.id, params: extract });

  return (
    <TemplateEspace espace={espace} user={user}>
      <div className="md:mx-6">
        <Form docId={extractId} model={model} type="update" />
      </div>
    </TemplateEspace>
  );
};

export default requireEspace(EspaceExtractUpdate);
